 :root, [data-amplify-theme] {
  --amplify-colors-orange-100: #F15A22;
  --amplify-colors-orange-90: #F47920;
  --amplify-colors-orange-80: #F49E00;
  --amplify-colors-brand-primary-100: var(--amplify-colors-orange-100);
  --amplify-colors-brand-primary-90: var(--amplify-colors-orange-90);
  --amplify-colors-brand-primary-80: var(--amplify-colors-orange-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-orange-60);
  --amplify-colors-brand-primary-40: var(--amplify-colors-orange-40);
  --amplify-colors-brand-primary-20: var(--amplify-colors-orange-20);
  --amplify-colors-brand-primary-10: var(--amplify-colors-orange-10);
  --amplify-components-button-color: var(--amplify-colors-font-primary);
}
[data-amplify-authenticator] {
  display: grid;
}
[data-amplify-authenticator][data-variation=modal] {
  overflow-y: auto;
  width: var(--amplify-components-authenticator-modal-width);
  height: var(--amplify-components-authenticator-modal-height);
  background: var(--amplify-colors-orange-80) url('/src/assets/img/notre-cover-auth.png') no-repeat right center/* todo use image with logo */;
  background-size: 50%;
  position: fixed;
  top: var(--amplify-components-authenticator-modal-top);
  left: var(--amplify-components-authenticator-modal-left);
  /* Having a z-index at least "wins" by default */
  z-index: 1;
}
[data-amplify-authenticator] [data-amplify-container] {
  place-self: start;
}
@media (min-width: 30rem) {
[data-amplify-authenticator] [data-amplify-container] {
    width: 50%;
}
}
[data-amplify-authenticator] [data-amplify-router] {
  background-color: var(--amplify-components-authenticator-router-background-color);
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  display: flex;
  border: none;
  box-shadow: none;
}